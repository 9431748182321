export const environment = {
    production: false,
    environment: "PROD",
    protocol: "https",
    path:"",
    // ip_address: "sakshiclassifieds.com/eclassfied-core/v1",
    ip_address: "sakshiclassified.com/eclassfied-core/v1",
    clientId:"sakshi_eclassifieds",
    clientPassword:"P@ssw0rd", 
    non_secure_id:"af857eab-0cb1-4dd2-a70b-66c0f4b0c652",
    maintainance_mode:false,
    maintainance_key:"",
    expireTime:10000,
    billdeskEnv:"PRD"
    // merchantId:"BDUATV2KTK",
    // billdeskClientId:"bduatv2ktk",
    // secret_key:"16uUloqqrs2iMUZnrojXtmkTeSQqjYIX"
};
