import { Component, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { filter } from 'rxjs/operators';
import { LocalService } from './services/local.service';

declare var $;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'SakshiEnews';
  previousUrl:any="";
  currentUrl:any="";
  constructor(private router:Router, private cookieService:CookieService, private localService:LocalService){

  }

  ngOnInit(){
    this.router.events.pipe(
      filter((event) => event instanceof NavigationStart)
      ).subscribe((event:any) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if((this.cookieService.get('token')=='' || this.cookieService.get('token') == null || this.cookieService.get('token')==undefined) && sessionStorage.getItem('logout')=='0'){
          this.localService.setJsonValue('previousUrl',this.previousUrl);
          sessionStorage.setItem('previousUrl',this.previousUrl);
        } 
        
      });
  }
}
