import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, map } from 'rxjs';
import * as FileSaver from 'file-saver';
import { ApiConfigurationService } from './api-configuration.service';
import { RequestPayload } from '../models/request-payload';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  requestPayload:RequestPayload = Object();
  private cartSubject = new Subject<String>();
  loadCartCount = this.cartSubject.asObservable();
  constructor(private environment:ApiConfigurationService,
    private toaster:ToastrService,private http: HttpClient) { }


  exportExcel(masterPayload:any,fileName:string){
    const url = this.environment.getRequiredApi("export_masters");
    this.requestPayload.url = url;
    this.requestPayload.body = masterPayload;
    this.postData(this.requestPayload).subscribe((res:any)=>{
      if(res!=null && res.status==0){
        let data:any = atob(res['data']);
        this.downloadFile(data,fileName,'xlsx');
      }else if(res!=null){
        this.toaster.warning(res.message);
      }
    })
  }

  genrateExcelForHeaders(masterPayload:any,fileName:string){
    const url = this.environment.getRequiredApi("genrate_excel_for_headers");
    this.requestPayload.url = url;
    this.requestPayload.body = masterPayload;
    this.postData(this.requestPayload).subscribe((res:any)=>{
      if(res!=null && res.status==0){
        let data:any = atob(res['data']);
        this.downloadFile(data,fileName,'xlsx');
      }else if(res!=null){
        this.toaster.warning(res.message);
      }
    })
  }

  uploadMasterData(payload:any){
    const url = this.environment.getRequiredApi("upload_master_data")
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.postAttachmentData(this.requestPayload);
  }

  downloadFile(fileData:any,fileName:string,fileType:string){
    const byteCharacters = fileData;
      const byteNumbers = new Array (byteCharacters.length);
      for (let i = 0; i <byteCharacters.length; i ++) {
        byteNumbers [i] = byteCharacters.charCodeAt (i);
      }
      const byteArray = new Uint8Array (byteNumbers);
      const data: Blob = new Blob([byteArray], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
      FileSaver.saveAs(data, fileName+"."+fileType);
  }

  getMasterData(masterType:any){
    const url = this.environment.getRequiredApi("get_masters")+masterType;
    this.requestPayload.url  = url;
    return this.getData(this.requestPayload);
   }

   getAllowedMastersData(){
    const url = this.environment.getRequiredApi("get_allowed_masters");
    this.requestPayload.url = url;
    return this.getData(this.requestPayload);
  }

  removeEmpty(obj:any){
    Object.keys(obj).forEach((k) => obj[k] == null && delete obj[k]);
    delete obj['mark_as_delete'];
    delete obj['created_by'];
    delete obj['created_ts'];
    delete obj['changed_by'];
    delete obj['changed_ts'];
    return obj;
  }
  uploadAttachment(payload:any,dealerAuditId:string,achievementId:string){
    const url = this.environment.getRequiredApi("upload_attachments")+"?action=1&att_type=1&dealerAuditId="+dealerAuditId+"&achievementId="+achievementId;
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.postAttachmentData(this.requestPayload);
  }

  deleteAttachment(docIds:any,dealerAuditId:string){
    const url = this.environment.getRequiredApi("upload_attachments")+"?action=3&att_type=1&dealerAuditId="+dealerAuditId+"&doc_id="+docIds;
    this.requestPayload.url = url;
    return this.postAttachmentData(this.requestPayload);
  }

  base64ToArrayBuffer(base64:any) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
  }

  uploadIncentiveAttachment(payload:any){
    const url = this.environment.getRequiredApi("upload_ic_attachments")
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.postAttachmentData(this.requestPayload);
  }

  uploadProfileAttachment(payload:any){
    const url = this.environment.getRequiredApi("upload_attachments")+"?action=5&att_type=1";
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.postAttachmentData(this.requestPayload);
  }

  uploadDocAttachment(payload:any){
    const url = this.environment.getRequiredApi("upload_doc_attachment");
    this.requestPayload.url = url;
    this.requestPayload.body = payload;
    return this.postAttachmentData(this.requestPayload);
  }

  downloadAttachments(docIds){
    const url = this.environment.getRequiredApi("view_attachments")+"?doc_id="+docIds;
    this.requestPayload.url = url;
    return this.getAttachmentData(this.requestPayload)
   }

   downloadAttachment(res,fileName,type?){
    const byteCharacters = atob(res['data']);
    const byteNumbers = new Array (byteCharacters.length);
    for (let i = 0; i <byteCharacters.length; i ++) {
      byteNumbers [i] = byteCharacters.charCodeAt (i);
    }
    const byteArray = new Uint8Array (byteNumbers);
    let data: Blob;
    let mimeType = '';
    let fileExtension = '';

    if (type == 1) { // If it's an image
        mimeType = 'image/png';
        fileExtension = 'png';
    } else if (type == 2) { // If it's a PDF
        mimeType = 'application/pdf;charset=UTF-8';
        fileExtension = 'pdf';
    }

    data = new Blob([byteArray], { type: mimeType });

    FileSaver.saveAs(data, `${fileName}`);
}

  viewAttachmentFile(res:any,ext:string){
    ext = ext.toUpperCase();
    const byteCharacters:any = atob(res);
    const byteNumbers = new Array (byteCharacters.length);
    for (let i = 0; i <byteCharacters.length; i ++) {
      byteNumbers [i] = byteCharacters.charCodeAt (i);
    }
    const byteArray = new Uint8Array (byteNumbers);
    let link:any=''
    let acceptFiles:any=['JPG','PNG','JPEG'];
    if(acceptFiles.indexOf(ext)!=-1){
      let blob = new Blob([byteArray], {type: "image/png"});
        link = window.URL.createObjectURL(blob);
    }else{
      if(ext=='PDF'){
        let blob = new Blob([byteArray], {type: "application/pdf"});
        link = window.URL.createObjectURL(blob);
      }
    }
    
    return link;
    //window.open(link);
   }
   getGlobalData(requestPayload){
    const  headers=new HttpHeaders({
      'Content-Type':  'application/json; charset=utf-8',
      'custom-loader': 'false'
    })
    const httpOptions = {
      headers:headers
    }
    return this.http.get<any>(requestPayload.url,httpOptions)
        .pipe(map(res=>{
          return res;
        })); 
  }

  getData(requestPayload) {
    const  headers=new HttpHeaders({
      'Content-Type':  'application/json; charset=utf-8'
    });
    const httpOptions = {
      headers: headers
    }
    let url = requestPayload.url;
    return this.http.get<any>(url,httpOptions).pipe(map(res => {
      return res;
    }));;
  }

  getPDFData(requestPayload) {
    const  headers=new HttpHeaders({
      'Content-Type':  'application/pdf',
      Accept : 'application/pdf',
    });
    let rsType:any = 'blob';
    const httpOptions = {
      headers: headers,
      responseType:rsType
    }
    let url = requestPayload.url;
    return this.http.get(url,httpOptions).pipe(map(res => {
      return res;
    }));
  }

  getZIPData(requestPayload) {
    const  headers=new HttpHeaders({});
    const rsType = 'blob' as const
    const httpOptions = {
      headers: headers,
      responseType:rsType
    }
    let url = requestPayload.url;
    return this.http.get(url,httpOptions).pipe(map(res => {
      return res;
    }));
  }

  postData(requestPayload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
    const httpOptions = {
      headers: headers
    }
    let body = requestPayload.body;
    requestPayload.url = requestPayload.url
    return this.http.post<any>(requestPayload.url, body, httpOptions);
  }

  postAutoSaveData(requestPayload,autoSave) {
    if(autoSave=='autoSave'){
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'custom-loader': 'false'
      })
      const httpOptions = {
        headers: headers
      }
      let body = requestPayload.body;
      requestPayload.url = requestPayload.url
      return this.http.post<any>(requestPayload.url, body, httpOptions);
    }else{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      })
      const httpOptions = {
        headers: headers
      }
      let body = requestPayload.body;
      requestPayload.url = requestPayload.url
      return this.http.post<any>(requestPayload.url, body, httpOptions);
    }
    
  }

  putData(requestPayload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
    const httpOptions = {
      headers: headers
    }
    let body = requestPayload.body;

    return this.http.put<any>(requestPayload.url, body, httpOptions);
  }

  deleteData(requestPayload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
    const httpOptions = {
      headers: headers
    }
    let body = requestPayload.body;
    let url = requestPayload.url
    return this.http.delete(url, httpOptions);
  }

  postGlobalData(requestPayload) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'custom-loader': 'false'
    })
    const httpOptions = {
      headers: headers
    }
    let body = requestPayload.body;
    requestPayload.url = requestPayload.url
    return this.http.post<any>(requestPayload.url, body, httpOptions);
  }

  postAttachmentData(requestPayload) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })
    const httpOptions = {
      headers: headers
    }
    let body = requestPayload.body;
    requestPayload.url = requestPayload.url
    return this.http.post<any>(requestPayload.url, body);
  }

  getAttachmentData(requestPayload){
    const  headers=new HttpHeaders({
      'Content-Type':  'application/json; charset=utf-8'
    });
    const httpOptions = {
      headers: headers
    }
    let url = requestPayload.url;
    return this.http.get<any>(url,httpOptions).pipe(map(res => {
      return res;
    }));
  }
  updateCartCount(count){
    this.cartSubject.next(<any>(count));
  }
  billDeskCreate(token){
    // let url:any="https://api.billdesk.com/payments/ve1_2/orders/create";
    let url:any="https://uat1.billdesk.com/u2/payments/ve1_2/orders/create";
    // let url = "https://pguat.billdesk.io/payments/ve1_2/orders/create"
    let billdeskInfo = this.environment.getEnvironment();
    if(billdeskInfo.billdeskEnv=='TEST'){
      url = "https://pguat.billdesk.io/payments/ve1_2/orders/create"
    }
    let timestamp = new Date().getTime();
    const  headers=new HttpHeaders({
      'content-Type':  'application/jose',
      'Bd-Timestamp':timestamp,
      'Bd-Traceid':timestamp+'ABD1K',
      'accept':'application/jose'
    });
    let rsType:any = 'text';
    const httpOptions = {
      headers:headers,
      responseType:rsType
    }
    return this.http.post<any>(url,token,httpOptions);
  }
  downloadExternalPDFFile(res,fileName){
    const byteCharacters = res;
      const byteNumbers = new Array (byteCharacters.length);
      for (let i = 0; i <byteCharacters.length; i ++) {
        byteNumbers [i] = byteCharacters.charCodeAt (i);
      }
      const byteArray = new Uint8Array (byteNumbers);
    // const data: Blob = new Blob([byteArray], {type:'application/pdf;charset=UTF-8'});
    const data: Blob = new Blob([byteArray], {type:'text/plain;charset=UTF-8'});
    FileSaver.saveAs(data, fileName);
   }
   getTranslateData(payload){
    const  headers=new HttpHeaders({
      'Content-Type':  'application/json; charset=utf-8',
    });
    let rsType:any = 'text' as 'json';
    const httpOptions = {
      headers:headers,
      responseType:rsType
    }
    return this.http.get<any>(payload.url,httpOptions);
   }
}
