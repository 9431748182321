import { NgModule, Self } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DefaultLayoutComponent } from './containers/default-layout/default-layout.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { SelfRegistrationComponent } from './components/self-registration/self-registration.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data:{
      breadcrumb: []
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data:{
      breadcrumb:[]
    }
  },
  { 
    path: 'resetPassword',
    component: LoginComponent,
    data:{
      breadcrumb:[]
    }
  },
  {
    path: 'selfRegistration',
    component: SelfRegistrationComponent,
    data:{
      breadcrumb:[]
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children:[
      {
        path: 'landingpage',
        component: LandingPageComponent,
        data:{
          breadcrumb: []
        }
      },
      {
        path:'dashboard',
        loadChildren: () => import('./components/dashboard/dashboard.module').then(dashboard => dashboard.DashboardModule),
        data:{
          breadcrumb: []
        }
      },
      {
        path:'admin',
        loadChildren: () => import('./components/user-management/user-management.module').then(userManagement => userManagement.UserManagementModule),
        data:{
          breadcrumb: []
        }
      },
      {
        path:'mastersModal',
        loadChildren: () => import('./components/masters/masters.module').then(master => master.MastersModule),
        data:{
          breadcrumb: []
        }
      },
      {
        path:'classified',
        loadChildren: () => import('./components/classified/classified.module').then(classified => classified.ClassifiedModule),
        data:{
          breadcrumb: []
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
